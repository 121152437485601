import home from '../../assets/icons/home.svg';
import createHTMLMapMarker from "../../javascript/helpers/HtmlMapMarker";


class MapHelper {

  constructor(map) {
    this.map = map;
    this.instance = new window.google.maps.places.PlacesService(map);
    this.currentMarkers = [];
    this.currentHomeMarkers = [];
  }

  query(query, callback) {
    //let service
    const request = {
      location: this.map.center,
      radius: '500',
      query: query
    }

    this.instance.textSearch(request, callback)
  }

  clearMarkers() {
    if (!this.currentMarkers || (this.currentMarkers.length === 0)) {
      return
    }

    for (let i = 0; i < this.currentMarkers.length; i++) {
      let marker = this.currentMarkers[i];
      marker.setMap(null);
      marker = null;
    }

    this.currentMarkers = [];
  }

  clearHomeMarkers() {
    if (!this.currentHomeMarkers || (this.currentHomeMarkers.length === 0)) {
      return
    }

    for (let i = 0; i < this.currentHomeMarkers.length; i++) {
      let marker = this.currentHomeMarkers[i];
      marker.setMap(null);
      marker = null;
    }

    this.currentHomeMarkers = [];
  }

  renderMarkers(markers) {
    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];
      const latLng = new window.google.maps.LatLng(marker.lat, marker.lng);
      const markerObj = createHTMLMapMarker({
        latlng: latLng,
        html: `
          <div class="Marker-Container" style="position: absolute; transform: translate(-50%, -50%);">
            <div class="Marker Default">
            </div>
          </div>
        `,
        map: this.map
      });

      this.currentMarkers.push(markerObj);
    };
  }

  setMapCenter(center) {
    //if (this.currentHomeMarkers.length > 0) {
      this.clearHomeMarkers();
    //}

    const homeMarker = new window.google.maps.Marker({
      position: center,
      map: this.map,
      icon: home,
      clickable: false,
      visible: true,
    });

    homeMarker.setMap(this.map);
    this.currentHomeMarkers.push(homeMarker);

  }
}

export default MapHelper;