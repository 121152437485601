//import _ from 'lodash';

class ElevationHelper {

  constructor() {
    this.instance = new window.google.maps.ElevationService();
  }

  fetchElevation(route, callBack) {
    //let latLngs = this.getLatLngsFromOverview(route.overview_path);
   // this.instance.getElevationForLocations({
   //   'locations': route.overview_path
   // }, callBack);

   if (!route.overview_path.length || (route.overview_path.length < 2)) {
    return;
   }

    this.instance.getElevationAlongPath({
      'path': route.overview_path,
      'samples': 500
    }, callBack);

    //this.instance.getElevationAlongPath({
    //  'path': route.overview_polyline,
    //  'samples': 256
    //}, callBack);
  }

  getLatLngs(legs) {
    let arr = [];
    for (let i = 0; i < legs.length; i++) {
      const leg = legs[i];
      for (let i = 0; i < leg.steps.length; i++) {
        const step = leg.steps[i];
        for (let i = 0; i < step.lat_lngs.length; i++) {
          const lat_lng = step.lat_lngs[i];
          arr.push({lat: lat_lng.lat(), lng: lat_lng.lng()});
        }
      } 
    }

    if (arr.length > 500) {
      console.log("throttling elevation points")
      return arr
    }

    return arr;
  }

  calculateElevation(results) {
    const feetInAMeter = 3.28084;

    if (!results || results.length === 0) {
      return "?";
    }

    let total = 0;

  /*  try {
      let maxEl = (_.maxBy(results, 'elevation').elevation);
      let minEl = (_.minBy(results, 'elevation').elevation);

      if (maxEl && minEl) {
        total = maxEl - minEl;
        total = (total * feetInAMeter).toFixed(0);
      }
    }
    catch (e) {
      console.log("elevation calc error:", e)
      total = "?";
    }

    return total*/

    for (let i = 0; i < results.length; i++) {
      const thisEl = results[i].elevation;
      const nextEl = results[i + 1] && results[i + 1].elevation;
      let diff;

      if (thisEl && nextEl) {
        diff = nextEl - thisEl;

        if (diff > 0) {
          total += diff;
        }
      }
    }


    let exparr = [];
    for (let el of results) {
      exparr.push(el.elevation);
    }


    total = (total * feetInAMeter).toFixed(0);
    return (total);

  }

}

export default new ElevationHelper();