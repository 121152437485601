import { createStore } from 'redux';

//import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducer from './reducer';

export const store = createStore(
  reducer,
  //applyMiddleware(thunk),
  composeWithDevTools(),
);