import React from 'react';
import { connect } from 'react-redux';
import SessionStore from '../../javascript/helpers/SessionStore';
import cn from 'classnames';

import {
  UPDATE_ADDRESS,
  UPDATE_MAP_LOCATION,
  PERFORM_INITIAL_QUERY,
  CLEAR_ROUTE,
} from "../../constants/actionTypes";

const mapDispatchToProps = dispatch => {
  return {
    updateAddress: (address) => {
      dispatch({ type: UPDATE_ADDRESS, address: address });
    },
    updateMapLocation: (center) => {
      dispatch({type: UPDATE_MAP_LOCATION, center: center})
    },
    onPerformInitialQuery: () => {
      dispatch({type: PERFORM_INITIAL_QUERY })
    },
    clear: () => {
      dispatch({ type: CLEAR_ROUTE });
    },
  };
};

const mapStateToProps = state => {
  return {
    address: state.maps.address,
    center: state.center,
    mapInstance: state.maps.mapInstance,
    performedInitialQuery: state.common.performedInitialQuery,
  }
};

class Address extends React.Component {

  componentWillReceiveProps = (nextProps) => {
    let {
      performedInitialQuery,
      center,
    } = this.props;

    //search if address is filled in when the map loads
    if (!center && !performedInitialQuery && this.props.mapInstance && (this.props.address)) {
      //this.props.onPerformInitialQuery();
      //this.submitAddress();
    }
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  constructor(props) {
    super(props);
    this.state = {
      results: [],
      showResults: false,
      searchLoading: false,
    }

    this.setWrapperRef = this.setWrapperRef.bind(this); 
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.showResults) {
      this.setState({showResults: false});
    }
  }

  render() {
    let {
      address,
    } = this.props;

    let {
      results, 
      showResults,
      searchLoading,
    } = this.state;

    return (
      <div className="Address-Container">
        <input value={address} placeholder="Address" type="text" onChange={(e) => this.props.updateAddress(e.target.value)} onKeyDown={(e) => this.onKeyPress(e)} />
        <button 
          className={cn(
            "submit", 
            searchLoading && "disabled",
          )}
          onClick={(e) => this.submitAddress()}
        >
          {searchLoading ? "Loading" : "Search"}
        </button>

        <div 
          className={cn(
            "results-container",
            results && (results.length > 0) && showResults && "shown",
          )}
          ref={this.setWrapperRef}
        >
          {this.renderResults(results)}
        </div>
      </div>
    )
  }

  renderResults = (results) => {
    if (!results || (results.length === 0)) {
      return
    }

    return results.map((result, i) => {

      return (
        <button
          onClick={(e) => this.clickResult(result)}
          key={i}
        >
          <div className="result">
            <p>{result.formatted_address}</p>
          </div>
        </button>
      )
    });
  }

  submitAddress = () => {
    let { address, mapInstance } = this.props;
    this.props.clear();
    if (mapInstance && address) {
      this.setState({searchLoading: true});
      mapInstance.query(address, this.handleMapsCallback);
    }
  }

  onKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.submitAddress();
    }
  }

  handleMapsCallback = (results, status) => {
    this.setState({results: results, showResults: true, searchLoading: false});
  }

  clickResult = (result) => {
    const coor = result.geometry.location;
    const formatted = {lat: coor.lat(), lng: coor.lng()};
    this.props.updateAddress(result.formatted_address);

    //this.props.updateHomeLocation(results[0]);
    this.props.updateMapLocation(formatted);
    this.setState({showResults: false, results: []});

    SessionStore.set("address", result.formatted_address);
    SessionStore.set("mapLocation", formatted);
  }


}



export default connect(mapStateToProps, mapDispatchToProps)(Address)