import React from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import MapHelper from '../../javascript/helpers/MapHelper';
import DirectionsHelper from '../../javascript/helpers/DirectionsHelper';
import ElevationHelper from '../../javascript/helpers/ElevationHelper';
import _ from 'lodash';

import Loading from '../../javascript/components/Loading';
import Controls from '../../javascript/components/controls/Controls';
///import DefaultMarker from '../../javascript/components/markers/DefaultMarker';
import CenterMarker from '../../javascript/components/markers/CenterMarker';
import ElevationMarker from '../../javascript/components/markers/ElevationMarker';
import { DateTime } from 'luxon';

import {
  MAP_OBJ_LOADED,
  MAP_INSTANCE_LOADED,
  DIRECTIONS_INSTANCE_LOADED,
  UPDATE_MAP_LOCATION,
  CREATE_MARKER,
  UPDATE_TOTAL_DISTANCE,
  UPDATE_ELEVATION_CHANGE,
  UPDATE_ELEVATION_DATA,
  UPDATE_API_COST,
  UPDATE_DIRECTIONS_LIST,
  UPDATE_LAST_ELEVATION_PING,
  UPDATE_ELEVATION_TIMEOUT_DATA,
} from "../../constants/actionTypes";

const mapDispatchToProps = dispatch => {
  return {
    mapObjectLoaded: (obj) => {
      dispatch({type: MAP_OBJ_LOADED, obj: obj})
    },
    setMapInstance: (mapInstance) => {
      dispatch({type: MAP_INSTANCE_LOADED, mapInstance: mapInstance})
    },
    setDirectionsInstance: (directionsInstance) => {
      dispatch({type: DIRECTIONS_INSTANCE_LOADED, directionsInstance: directionsInstance})
    },
    updateMapLocation: (center) => {
      dispatch({type: UPDATE_MAP_LOCATION, center: center})
    },
    createMarker: (marker) => {
      dispatch({type: CREATE_MARKER, marker: marker})
    },
    updateTotalDistance: (dist) => {
      dispatch({type: UPDATE_TOTAL_DISTANCE, totalDistance: dist})
    },
    updateElevationChange: (ele) => {
      dispatch({type: UPDATE_ELEVATION_CHANGE, elevationChange: ele})
    },
    updateElevationData: (arr) => {
      dispatch({type: UPDATE_ELEVATION_DATA, results: arr})
    },
    updateUsageCost: (float) => {
      dispatch({type: UPDATE_API_COST, usageCost: float})
    },
    updateDirectionsList: (steps) => {
      dispatch({type: UPDATE_DIRECTIONS_LIST, steps: steps})
    },
    updateLastElevationPing: (dateTime) => {
      dispatch({type: UPDATE_LAST_ELEVATION_PING, dateTime: dateTime})
    },
    setElevationTimeoutData: (intervalId, route) => {
      dispatch({type: UPDATE_ELEVATION_TIMEOUT_DATA, intervalId: intervalId, route: route})
    }
  };
};

const mapStateToProps = state => {
  return {
    loading: state.maps.loading,
    address: state.maps.address,
    apiKey: state.keys.googleMaps,
    center: state.maps.center,
    defaultCenter: state.maps.defaultCenter,
    mapObj: state.maps.mapObj,
    mapInstance: state.maps.mapInstance,
    directionsInstance: state.maps.directionsInstance,
    markers: state.markers.markers,
    elevationTracker: state.markers.elevationTracker,
    defaultZoom: state.maps.defaultZoom,
    lastElevationPing: state.markers.lastElevationPing,
    elevationIntervalId: state.markers.elevationIntervalId,
    delayedElevationRoute: state.markers.delayedElevationRoute,
  }
};

class MapView extends React.Component {

  componentWillReceiveProps = (nextProps) => {
    let {
      mapInstance,
      center,
      mapObj,
    } = this.props;

    if (!mapInstance && !mapObj && nextProps.mapObj) {
      let instance = new MapHelper(nextProps.mapObj);
      let dirInstance = new DirectionsHelper(nextProps.mapObj);
      this.props.setMapInstance(instance);
      this.props.setDirectionsInstance(dirInstance);

      !center && nextProps.center && mapInstance.setMapCenter(nextProps.center);
    }

    if ((this.props.center !== nextProps.center) && mapInstance) {
      mapInstance.setMapCenter(nextProps.center);
    }

    if (!mapInstance && nextProps.mapInstance && nextProps.center) {
      nextProps.mapInstance.setMapCenter(nextProps.center)
    }
  }

  setInitialSettings = () => {
    return {
      draggableCursor: 'crosshair',
      fullscreenControl: false,
    }
  }

  render() {
    let {
      apiKey,
      elevationTracker,
      loading,
    } = this.props;

    if (loading) {
      return (
        <Loading />
      )
    }

    return (
      <div className="Map-Container">
        <Controls />
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          //defaultCenter={this.props.defaultCenter}
          center={this.props.center ? this.props.center : this.props.defaultCenter}
          defaultZoom={this.props.defaultZoom}
          options={this.setInitialSettings}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
          onClick={this.handleMapClicked}
        >

          {this.renderMarkers()}

          {elevationTracker && (
            <ElevationMarker
              lat={elevationTracker.lat}
              lng={elevationTracker.lng}
            />
          )}
        </GoogleMapReact>
      </div>
    )
  }

  renderMarkers = () => {
    let {
      markers,
      mapInstance,
    } = this.props;

    if ((markers.length === 0) || !mapInstance) {
      return;
    }

    mapInstance.clearMarkers();
    mapInstance.renderMarkers(markers);

   // return markers.map((marker, i) => {
   //   return (
   //     <DefaultMarker
   //       key={i}
   //       lat={marker.lat}
   //       lng={marker.lng}
   //       text={marker.text}
   //     />
   //   )
    //});
  }

  renderCenter = () => {

    let {
      center,
    } = this.props;

    return (
      <CenterMarker
        lat={center.lat}
        lng={center.lng}
      />
    )
  }

  handleApiLoaded = (map, maps) => {
    this.props.mapObjectLoaded(map);
  }

  handleMapClicked = async ({x, y, lat, lng, event}) => {
    let { markers } = this.props;
    let position = 1;
    if (markers.length > 0) {
      position = _.orderBy(markers, ['position'],['desc'])[0].position + 1;
    }

    const marker = {
      lat: lat,
      lng: lng,
     // text: "stuff",
      position: position,
    }

    await this.props.createMarker(marker);
    if ((this.props.markers.length > 1) && this.props.directionsInstance) {
      this.props.directionsInstance.query(this.props.markers, this.handleDirectionsResults)
    }
  }

  handleDirectionsResults = (response, status) => {
    const usageCost = (this.props.markers.length >= 13) ? 0.01 : 0.005;
    this.props.directionsInstance.onResults(response);
    this.props.updateUsageCost(usageCost);
    if (response.routes[0]) {
      const distance = this.props.directionsInstance.calculateDistance(response.routes[0]);
      this.props.updateTotalDistance(distance);
      this.maybeRequestElevation(response.routes[0]);
      this.updateDirectionsList(response.routes[0]);
    }
  }

  maybeRequestElevation = (route) => {
    let { elevationIntervalId, lastElevationPing } = this.props;
    const now = DateTime.local();
    let intervalId;

    if (!lastElevationPing || (now.diff(lastElevationPing, 'seconds').seconds > 2)) {
      this.props.updateLastElevationPing(now);
      ElevationHelper.fetchElevation(route, this.handleElevationResults);
      return
    }

    if (lastElevationPing && (now.diff(lastElevationPing, 'seconds').seconds <= 2)) {
      if (elevationIntervalId) {
        clearTimeout(elevationIntervalId);
      }

      intervalId = setTimeout(this.delayedElevationFetch, 2000);
      this.props.setElevationTimeoutData(intervalId, route);
      return
    }

    ElevationHelper.fetchElevation(route, this.handleElevationResults);
  }

  delayedElevationFetch = () => {
    let { delayedElevationRoute } = this.props;
    if (!delayedElevationRoute) {
      return
    }

    ElevationHelper.fetchElevation(delayedElevationRoute, this.handleElevationResults)
  }

  handleElevationResults = (results, status) => {
    if (!results) {
      return;
    }
    
    this.props.updateElevationData(results);
    this.props.updateUsageCost(0.005);
    const elevation = ElevationHelper.calculateElevation(results);
    this.props.updateElevationChange(elevation);
  }

  updateDirectionsList = (route) => {
    this.props.updateDirectionsList([]);
    if (!route) {
      return
    }
    let steps = [];
    if (route.legs && (route.legs.length > 0)) {
      route.legs.forEach((leg, i) => {
        if (leg.steps && (leg.steps.length > 0)) {
          leg.steps.forEach((step, ix) => {
            steps.push(step);
          });
        }
      });
    }

    this.props.updateDirectionsList(steps);
  }

}



export default connect(mapStateToProps, mapDispatchToProps)(MapView)