import SessionStore from '../javascript/helpers/SessionStore';

import {
  UPDATE_AUTH_PASSWORD,
  TOGGLE_AUTH_LOADING,
  UPDATE_MAP_SHOWN,
  UPDATE_ADDRESS,
  MAP_OBJ_LOADED,
  MAP_INSTANCE_LOADED,
  DIRECTIONS_INSTANCE_LOADED,
  UPDATE_MAP_LOCATION,
  TOGGLE_MAP_LOADING,
  UPDATE_API_COST,
} from '../constants/actionTypes';

const initialMapCenter = SessionStore.get("mapLocation");
const initialAddress = SessionStore.get("address");

const initialState = {
  address: initialAddress || "",
  displayMap: false,
  defaultCenter: {
    lat: 59.95,
    lng: 30.33
  },
  defaultZoom: 15,
  loading: false,
  authLoading: false,
  authPassword: "",
  usageCost: 0.00,
  center: initialMapCenter || null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AUTH_PASSWORD:
      return {
        ...state,
        authPassword: action.password,
      }
    case TOGGLE_AUTH_LOADING:
      return {
        ...state,
        authLoading: action.loading,
      }
    case TOGGLE_MAP_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case UPDATE_ADDRESS:
      return {
        ...state,
        address: action.address,
      }
    case UPDATE_MAP_SHOWN:
      return {
        ...state,
        displayMap: action.display,
      }
    case MAP_OBJ_LOADED:
      return {
        ...state,
        mapObj: action.obj,
      }
    case MAP_INSTANCE_LOADED:
      return {
        ...state,
        mapInstance: action.mapInstance,
      }
    case DIRECTIONS_INSTANCE_LOADED:
      return {
        ...state,
        directionsInstance: action.directionsInstance,
      }
    case UPDATE_MAP_LOCATION:
      return {
        ...state,
        center: action.center,
      }
    case UPDATE_API_COST:
      return {
        ...state,
        usageCost: (state.usageCost + action.usageCost),
      }
    default:
      return state;
  }
};