import Cookies from 'universal-cookie';

class SessionStore {
  constructor() {
    this.cookies = new Cookies();
  }

  set(name, data) {
    this.cookies.set(name, JSON.stringify(data), { path: '/' });
  }

  get(name) {
    let val = "";
    val = this.cookies.get(name, {});
    val = val || "";

    return val
  }

  remove(name) {
    this.cookies.remove(name, {});
  }
}

export default new SessionStore();