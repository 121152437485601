import _ from "lodash";

import {
  CREATE_MARKER,
  DESTROY_LAST_MARKER,
  CLEAR_ROUTE,
  UPDATE_TOTAL_DISTANCE,
  UPDATE_ELEVATION_CHANGE,
  UPDATE_ELEVATION_DATA,
  UPDATE_ELEVATION_TRACKER,
  CLEAR_ELEVATION_TRACKER,
  UPDATE_DIRECTIONS_LIST,
  UPDATE_LAST_ELEVATION_PING,
  UPDATE_ELEVATION_TIMEOUT_DATA,
} from '../constants/actionTypes';

const initialState = {
  markers: [],
  totalDistance: 0,
  elevationChange: 0,
  elevationData: [],
  steps: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MARKER:
      let updatedMarkers = _.orderBy([...state.markers], ['position'],['asc']);
      updatedMarkers.push(action.marker);
      return {
        ...state,
        markers: updatedMarkers,
      }
    case DESTROY_LAST_MARKER:
      return {
        ...state,
        markers: action.markers,
      }
    case CLEAR_ROUTE:
      return {
        ...state,
        markers: [],
        elevationData: [],
        steps: [],
        totalDistance: 0,
        elevationChange: 0,
      }
    case UPDATE_TOTAL_DISTANCE:
      return {
        ...state,
        totalDistance: action.totalDistance,
      }
    case UPDATE_ELEVATION_CHANGE:
     return {
        ...state,
        elevationChange: action.elevationChange,
     }
    case UPDATE_ELEVATION_DATA:
      return {
        ...state,
        elevationData: action.results,
      }
    case UPDATE_ELEVATION_TRACKER:
      return {
        ...state,
        elevationTracker: action.latLng,
      }
    case CLEAR_ELEVATION_TRACKER:
      return {
        ...state,
        elevationTracker: "",
      }
    case UPDATE_DIRECTIONS_LIST:
      return {
        ...state,
        steps: action.steps,
      }
    case UPDATE_LAST_ELEVATION_PING:
      return {
        ...state,
        lastElevationPing: action.dateTime,
      }
    case UPDATE_ELEVATION_TIMEOUT_DATA:
      return {
        ...state,
        elevationIntervalId: action.intervalId,
        delayedElevationRoute: action.route,
      }
    default:
      return state;
  }
};