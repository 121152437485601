import React from 'react';

class Loading extends React.Component {

  render() {

    return (
      <div className="Loading-Container">
        <p>loading...</p>
      </div>
    )
  }


}


export default Loading;