export const LOAD_API_KEY = 'LOAD_API_KEY';
export const UPDATE_AUTH_PASSWORD = 'UPDATE_AUTH_PASSWORD';
export const TOGGLE_AUTH_LOADING = 'TOGGLE_AUTH_LOADING';

export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const NEW_ERRORS = 'NEW_ERRORS';
export const REQ_START = 'REQ_START';
export const REQ_SUCCESS = 'REQ_SUCCESS';
export const REQ_FAILURE = 'REQ_FAILURE';
export const PAGE_LOAD = 'PAGE_LOAD';
export const PERFORM_INITIAL_QUERY = 'PERFORM_INITIAL_QUERY';
export const TOGGLE_MAP_LOADING = 'TOGGLE_MAP_LOADING';

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_MAP_SHOWN = 'UPDATE_MAP_SHOWN';

export const MAP_OBJ_LOADED = 'MAP_OBJ_LOADED';
export const MAP_INSTANCE_LOADED = 'MAP_INSTANCE_LOADED';
export const DIRECTIONS_INSTANCE_LOADED = 'DIRECTIONS_INSTANCE_LOADED';
export const UPDATE_MAP_LOCATION = 'UPDATE_MAP_LOCATION';
export const UPDATE_API_COST = 'UPDATE_API_COST';

export const CREATE_MARKER = 'CREATE_MARKER';
export const DESTROY_LAST_MARKER = 'DESTROY_LAST_MARKER';
export const CLEAR_ROUTE = 'CLEAR_ROUTE';
export const UPDATE_TOTAL_DISTANCE = 'UPDATE_TOTAL_DISTANCE';
export const UPDATE_ELEVATION_CHANGE = 'UPDATE_ELEVATION_CHANGE';
export const UPDATE_ELEVATION_DATA = 'UPDATE_ELEVATION_DATA';
export const UPDATE_ELEVATION_TRACKER = 'UPDATE_ELEVATION_TRACKER';
export const CLEAR_ELEVATION_TRACKER = 'CLEAR_ELEVATION_TRACKER';
export const UPDATE_LAST_ELEVATION_PING = 'UPDATE_LAST_ELEVATION_PING';
export const UPDATE_ELEVATION_TIMEOUT_DATA = 'UPDATE_ELEVATION_TIMEOUT_DATA';


export const UPDATE_DIRECTIONS_LIST = 'UPDATE_DIRECTIONS_LIST';

