import common from './reducers/common';
import keys from './reducers/keys';
import maps from './reducers/maps';
import markers from './reducers/markers';
//import auth from './reducers/auth';
import { combineReducers } from 'redux';

export default combineReducers({
  common,
  keys,
  maps,
  markers,
});