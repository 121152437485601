class GeolocationHelper {

  attemptLocationFetch(callback, loadingCallback) {
    if (navigator.geolocation) {
       loadingCallback(true);
    }

    navigator.geolocation.getCurrentPosition(function(position) {
      callback(position.coords.latitude, position.coords.longitude);
    }, function() {
      loadingCallback(false);
    },
    {
      timeout:5000
    });
  }
}

export default new GeolocationHelper();