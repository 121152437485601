import React from 'react';
import cn from 'classnames';
import axios from 'axios';
import SessionStore from '../../javascript/helpers/SessionStore';


import { connect } from 'react-redux';

import {
  LOAD_API_KEY,
  UPDATE_AUTH_PASSWORD,
  TOGGLE_AUTH_LOADING,
} from '../../constants/actionTypes';


const mapDispatchToProps = dispatch => {
  return {
    updatePassword: (password) => {
      dispatch({ type: UPDATE_AUTH_PASSWORD, password: password });
    },
    loadApiKey: (key) => {
      dispatch({type: LOAD_API_KEY, key: key})
    },
    toggleAuthLoading: (bool) => {
      dispatch({type: TOGGLE_AUTH_LOADING, loading: bool})
    }
  };
};

const mapStateToProps = state => {
  return {
    password: state.maps.authPassword,
    loading: state.maps.authLoading,
  }
};

class Auth extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      error: "",
    };
  }

  render() {
    let {
      password,
      loading,
    } = this.props;

    let {
      error,
    } = this.state;

    return (

      <div 
        id="auth"
      >
        {error && (error.length > 0) && (
          <p className="error">{error}</p>
        )}
        <input value={password} placeholder="Password" type="password" onChange={(e) => this.props.updatePassword(e.target.value)} onKeyUp={(e) => this.onKeyUp(e)} />
        <button 
          onClick={(e) => this.submitPassword()} 
          className={cn(
            loading && "disabled",
          )}
        >
          Submit
        </button>
      </div>
    )
  }

  onKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.submitPassword();
    }
  }

  submitPassword = async () => {
    this.props.toggleAuthLoading(true);
    this.setState({error: ""});
    let path = (process.env.NODE_ENV === "production") ? 'https://simpletab-us-api.herokuapp.com/api/v1/external_apps/run_tracker_auth' : 'http://localhost:3001/api/v1/external_apps/run_tracker_auth';

    let {
      password,
    } = this.props;

    path = path + `?password=${password}`;

    if (!password || (password.length < 8)) {
      this.setState({error: "Password must be at least 8 characters."});
      this.props.toggleAuthLoading(false);
      return
    }

    const res = await axios.get(path, {
      cancelToken: this.signal.token,
    });

    if (res.data.success) {
      this.props.loadApiKey(res.data.key);
      this.props.toggleAuthLoading(false);
      SessionStore.set("google-maps-api-key", res.data.key);
      this.props.history.push("/");
      return
    }

    this.setState({error: res.data.error});
    this.props.toggleAuthLoading(false);

  }


}



export default connect(mapStateToProps, mapDispatchToProps)(Auth)