import React from 'react';
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch => {
  return {
  };
};

const mapStateToProps = state => {
  return {
    steps: state.markers.steps
  }
};

class Directions extends React.Component {

  render() {
    let {
      steps,
    } = this.props;

    return (
      <div className="Directions-Container">
        <p className="title">Directions</p>

        {(!steps || (steps.length === 0)) && (
          <p className="no-content">nothing yet</p>
        )}

        {(steps && (steps.length > 0)) && (
          <div className="steps">
            {this.renderSteps(steps)}
          </div>
        )}
      </div>
    )
  }

  renderSteps(steps) {
    return steps.map((step, i) => {

      let instructions = step.instructions;
      let regex;
      //let isChecked = true;

      if (instructions.includes("Destination will be on the left")) {
        regex = /Destination will be on the left/gi;
      } else if (instructions.includes("Destination will be on the right")) {
        regex = /Destination will be on the right/gi;
      }

      if (regex) {
        instructions = instructions.replace(regex, '')
      }

      return (
        <div
          className="step"
          key={i}
        >
          <p className="distance">
            {step.distance.text}
          </p>

          <p 
            className="instruction"
            dangerouslySetInnerHTML={{__html: instructions}}
          />

          {/*<input
            name="addToRoute"
            type="checkbox"
            checked={isChecked}
            onChange={(e) => this.toggleDirection(step)} />*/}
        </div>
      )
    });
  }

  toggleDirection = (step) => {
    console.log("toggle directions", step)
  }

}



export default connect(mapStateToProps, mapDispatchToProps)(Directions)