import React, { Component } from 'react';
import './stylesheets/application.scss';

import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import cn from 'classnames';
import { connect } from 'react-redux';

import { PAGE_LOAD } from "./constants/actionTypes";

import RunTracker from "./javascript/views/RunTracker";
import Auth from "./javascript/views/Auth";


//renders flash messages
import helpers from './javascript/components/utils/helpers';

const mapDispatchToProps = dispatch => {
  return {
    onPageLoad: () => {
      dispatch({ type: PAGE_LOAD });
    }
  };
};

const mapStateToProps = state => {
  return {
    errors: state.common.errors,
    loading: state.common.loading,
  }
};

class Dashboard extends Component {
  history = createBrowserHistory();

  componentDidMount = () => {
    this.unlisten = this.history.listen((location, action) => {
      this.props.onPageLoad();
    });
  }

  componentWillUnmount = () => {
    this.unlisten();
  }

  render() {
    let {
      errors,
      loading,
    } = this.props;

    return (
      <div 
        className={cn(
          "App",
          loading ? "loading" : "",
        )}
      >

        {((errors.length > 0)) && (
          <div className="messages">
            {helpers.renderMessages(errors, this.handleRemoveFlashMessage)}
          </div>
        )}

        <Router history={this.history}>
          <div>
            <Switch>
              <Route
                exact path="/"
                component={RunTracker}
              />

              <Route
                exact path="/auth"
                component={Auth}
              />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);