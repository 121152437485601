//import SessionStore from '../javascript/helpers/SessionStore';

import {
  LOAD_API_KEY,
} from '../constants/actionTypes';

const initialState = {
  googleMaps: "AIzaSyAA5Qx8R_BhURgECawVOM17EkVxh08ee_w" //SessionStore.get("google-maps-api-key")
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_API_KEY:
      return {
        ...state,
        googleMaps: action.key,
      }
    default:
      return state;
  }
};