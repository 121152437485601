import React from 'react';

class ElevationMarker extends React.Component {

  render() {

    const style = {
      position: 'absolute',
      transform: 'translate(-50%, -50%)'
    }

    return (
      <div className="Marker-Container" style={style}>
        <div className="Marker Elevation">
        </div>
      </div>
    )
  }


}


export default ElevationMarker;