import React from 'react';
import { connect } from 'react-redux';
import {FlexibleWidthXYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries} from 'react-vis';
import _ from 'lodash';

import {
  UPDATE_ELEVATION_TRACKER,
  CLEAR_ELEVATION_TRACKER,
} from "../../constants/actionTypes";

const mapDispatchToProps = dispatch => {
  return {
    updateElevationTracker: (coor) => {
      dispatch({type: UPDATE_ELEVATION_TRACKER, latLng: coor})
    },
    clearElevationMarker: () => {
      dispatch({type: CLEAR_ELEVATION_TRACKER})
    }
  };
};

const mapStateToProps = state => {
  return {
    elevationData: state.markers.elevationData,
    totalDistance: state.markers.totalDistance,
  }
};

class ElevationMap extends React.Component {

  render() {
    let {
      elevationData,
    } = this.props;

    const data = this.formatData(elevationData);
    
    return (
      <div className="Elevation-Map">

        <span className="y-label">elevation (ft)</span>
        <span className="x-label">distance (mi)</span>
        <div 
          className="Elevation-Map-Container"
          id="Elevation-Map-Container"
        >
          <div>
            <FlexibleWidthXYPlot
              //width={this.getWidth()}
              height={200}
              onMouseLeave={this.handleMoustLeave}
            >
              <HorizontalGridLines />
              <LineSeries
                color="#44A1A0"
                data={data}
                onNearestX={this.handleMouse}
              />
              <XAxis />
              <YAxis />
            </FlexibleWidthXYPlot>
          </div>
        </div>
      </div>
    )
  }

  handleMoustLeave = () => {
    this.props.clearElevationMarker();
  }

  handleMouse = (value, {event, innerX, index}) => {
    let { elevationData } = this.props;
    const val = _.find(elevationData, {elevation: value.y});
    
    if (val) {
      const coord = { lat: val.location.lat(), lng: val.location.lng() }
      this.props.updateElevationTracker(coord);
    }

  }

  formatData(elevationData) {
    if (elevationData.length === 0) {
      return [{x: 0, y:0}];
    }

    let arr = [];
    const xUnits = this.getXUnits();

    for (let i = 0; i < elevationData.length; i++) {
      const point = elevationData[i];
      const y = point.elevation;
      const x = parseFloat((xUnits * (i + 1)).toFixed(2));

      arr.push({x: x, y: y});
    }

    return arr;
  }

  getXUnits = () => {
    let {
      totalDistance,
      elevationData,
    } = this.props;

    const distanceFloat = parseFloat(totalDistance);
    const xUnits = distanceFloat / elevationData.length;

   // console.log(totalDistance, distanceFloat, elevationData.length, )

    return xUnits;
  }


}



export default connect(mapStateToProps, mapDispatchToProps)(ElevationMap)