import _ from "lodash";

class DirectionsHelper {

  constructor(map) {
    this.map = map;
    this.service = new window.google.maps.DirectionsService();
    this.display = new window.google.maps.DirectionsRenderer();
    this.display.setOptions({
      preserveViewport: true,
      markerOptions: {
        visible: false,
      },
      polylineOptions: {
        strokeOpacity: 0.4,
        strokeWeight: 3,
        strokeColor: "#44A1A0",
      }
    });

    this.display.setMap(map);
  }

  query(markers, callback) {
    if (markers.length === 0) {
      this.clearDisplay();
      return;
    }

    let markersToUpdate = [...markers];

    const firstMarker = markers[0];
    const lastMarker = markers.slice(-1)[0];
    _.remove(markersToUpdate, (m) => (m.position === firstMarker.position));
    _.remove(markersToUpdate, (m) => (m.position === lastMarker.position));

    const waypoints = this.formatWaypoints(markersToUpdate);

    this.service.route({
      origin: this.getLatLng(firstMarker),
      destination: this.getLatLng(lastMarker),
      waypoints: waypoints,
      travelMode: 'WALKING',
      provideRouteAlternatives: false,
      avoidHighways: true,
      avoidFerries: true,
      avoidTolls: true,
    }, callback) 
  }

  getLatLng(obj) {
    return {lat: obj.lat, lng: obj.lng}
  }

  onResults(res) {
    this.display.setDirections(res);
  }

  formatWaypoints(markers) {
    let result = [];
    for (let i = 0; i < markers.length; i++) {
      result.push({location: this.getLatLng(markers[i])});
    };

    return result;
  }

  calculateDistance(route) {
    let total = 0;
    for (let i = 0; i < route.legs.length; i++) {
      total += route.legs[i].distance.value;
    }

    total = ((total / 1000) * 0.62137119).toFixed(2);
    return (total);
  }

  clearDisplay() {
    this.display.set('directions', null);
  }
}

export default DirectionsHelper;