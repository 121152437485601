import {
  REQ_START,
  REQ_SUCCESS,
  REQ_FAILURE,
  PAGE_LOAD,
  TOGGLE_LOADING,
  NEW_ERRORS,
  PERFORM_INITIAL_QUERY,
} from '../constants/actionTypes';

const initialState = {
  loading: false,
  errors: [],
  address: "",
  performedInitialQuery: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGE_LOAD:
      return {
        ...state,
        isFetching: false,
        reqFinished: false,
        success: false,
        errors: []
      };
    case PERFORM_INITIAL_QUERY:
      return {
        ...state,
        performedInitialQuery: true,
      }
    case REQ_START:
      return {
        ...state,
        isFetching: true,
        success: false,
      };
    case REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reqFinished: true,
        success: true,
      };
    case REQ_FAILURE:
      return {
        ...state,
        errors: action.errors,
        isFetching: false,
        reqFinished: true,
        success: false,
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case NEW_ERRORS:
     return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};