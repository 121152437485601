import React from 'react';
import home from '../../../assets/icons/home.svg';

class CenterMarker extends React.Component {

  render() {

    const style = {
      position: 'absolute',
      transform: 'translate(-50%, -50%)'
    }

    return (
      <div className="Marker-Container" style={style}>
        <div className="Marker Center">
          <img src={home} alt="home" />
        </div>
      </div>
    )
  }


}


export default CenterMarker;