import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import Dashboard from './Dashboard';

class App extends React.Component {

  render() {
    return (
      <Provider store={store}>
        <Dashboard />
      </Provider> 
    )
  }


}



export default App