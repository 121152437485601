import React from 'react'
import cn from 'classnames'

let helpers = {
  renderMessages(messages, removeMessageCallback=null) {
    return (
      messages.map((m,i) => {
        let status = "error"
        if (m.status) {
          status = m.status
        }

        return (
          <div 
            className={cn(
              "notification",
              status
            )}
            key={i}
          >
            <p dangerouslySetInnerHTML={{__html: m.text}} />

            {removeMessageCallback && (
              <button 
                className="remove-message"
                onClick={(e) => removeMessageCallback(messages, m)}
              >
                <i 
                  className="material-icons cancel-message"
                >
                  cancel_circle_outline
                </i>
              </button>
            )}
          </div>
        )
      })
    )
  },
  removeMessage(messages, message) {
    const index = messages.indexOf(message)
    messages.splice(index, 1)

    return messages
  }
}

export default helpers