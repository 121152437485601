import React from 'react';
import Address from '../../javascript/components/Address';
import MapView from '../../javascript/components/MapView';
//import UsageMeter from '../../javascript/components/UsageMeter';
import Directions from '../../javascript/components/Directions';
import ElevationMap from '../../javascript/components/ElevationMap';
import GeolocationHelper from '../../javascript/helpers/GeolocationHelper';
import { withRouter, Prompt } from "react-router-dom";
import cn from 'classnames';

import { connect } from 'react-redux';

import {
  UPDATE_MAP_SHOWN,
  UPDATE_MAP_LOCATION,
  TOGGLE_MAP_LOADING,
} from '../../constants/actionTypes';


const mapDispatchToProps = dispatch => {
  return {
    updateMapShown: (bool) => {
      dispatch({type: UPDATE_MAP_SHOWN, display: bool})
    },
    updateMapLocation: (center) => {
      dispatch({type: UPDATE_MAP_LOCATION, center: center})
    },
    toggleMapLoading: (bool) => {
      dispatch({type: TOGGLE_MAP_LOADING, loading: bool})
    }
  };
};

const mapStateToProps = state => {
  return {
    displayMap: state.maps.displayMap,
    center: state.maps.center,
    loading: state.maps.loading,
    apiKey: state.keys.googleMaps,
    markers: state.markers.markers,
  }
};

class RunTracker extends React.Component {
  constructor(props, context) {
    super();
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentWillReceiveProps = (nextProps) => {
    let {
      center,
    } = this.props;

    if (!center && nextProps.center) {
      this.props.updateMapShown(true);
    }

  }

  componentDidMount = () => {
    if (this.props.apiKey.length === 0) {
      this.props.history.push("/auth");
      return
    }

    if (this.props.center) {
      this.props.updateMapShown(true);
    }

    if (("geolocation" in navigator) && !this.props.center) {
    /* geolocation is available */
      console.log("attempting gelo")
      GeolocationHelper.attemptLocationFetch(this.handleGelolocationResults, this.props.toggleMapLoading);
    }

    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup(e) {
    let { markers } = this.props;
    if (markers && (markers.length > 0) && e) {
      const confirmationMessage = "Are you sure you want to leave? Route will be lost.";     
      e.returnValue = confirmationMessage;           
      return confirmationMessage;  
    }
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  handleGelolocationResults = (lat, lng) => {
    const formatted = {lat: lat, lng: lng};
    this.props.updateMapLocation(formatted);
    this.props.toggleMapLoading(false);
  }

  render() {
    let {
      displayMap,
      loading,
      markers,
    } = this.props;

    return (

      <div 
        id="run"
        className={cn(
          displayMap && "show-map"
        )}
      >
        {!loading && !displayMap && (
          <div className="message-container">
            <p className="message">Enter an address to get started.</p>
          </div>
        )}

        <Prompt
          when={(markers && (markers.length > 0))}
          message="Are you sure you want to leave? Route will be lost."
        />

        <div className="content-container">
          <div className="main">
            <Address />
            <MapView />
            <ElevationMap />
          </div>

          <div className="side">
            {/*<UsageMeter />*/}
            <Directions />
          </div>
        </div>
      </div>
    )
  }


}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RunTracker));