import React from 'react';
import undo from '../../../assets/icons/undo.svg';
import clear from '../../../assets/icons/clear.svg';
import _ from 'lodash';
import ElevationHelper from '../../../javascript/helpers/ElevationHelper';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
//import _ from 'lodash';

import {
  DESTROY_LAST_MARKER,
  CLEAR_ROUTE,
  UPDATE_TOTAL_DISTANCE,
  UPDATE_ELEVATION_CHANGE,
  UPDATE_DIRECTIONS_LIST,
  UPDATE_LAST_ELEVATION_PING,
  UPDATE_API_COST,
  UPDATE_ELEVATION_DATA,
  UPDATE_ELEVATION_TIMEOUT_DATA,
} from "../../../constants/actionTypes";

const mapDispatchToProps = dispatch => {
  return {
    undo: (markers) => {
      dispatch({ type: DESTROY_LAST_MARKER, markers: markers });
    },
    clear: () => {
      dispatch({ type: CLEAR_ROUTE });
    },
    updateTotalDistance: (dist) => {
      dispatch({type: UPDATE_TOTAL_DISTANCE, totalDistance: dist})
    },
    updateElevationChange: (ele) => {
      dispatch({type: UPDATE_ELEVATION_CHANGE, elevationChange: ele})
    },
    updateDirectionsList: (steps) => {
      dispatch({type: UPDATE_DIRECTIONS_LIST, steps: steps})
    },
    updateLastElevationPing: (dateTime) => {
      dispatch({type: UPDATE_LAST_ELEVATION_PING, dateTime: dateTime})
    },
    updateElevationData: (arr) => {
      dispatch({type: UPDATE_ELEVATION_DATA, results: arr})
    },
    setElevationTimeoutData: (intervalId, route) => {
      dispatch({type: UPDATE_ELEVATION_TIMEOUT_DATA, intervalId: intervalId, route: route})
    },
    updateUsageCost: (float) => {
      dispatch({type: UPDATE_API_COST, usageCost: float})
    },
  };
};

const mapStateToProps = state => {
  return {
    markers: state.markers.markers,
    totalDistance: state.markers.totalDistance,
    elevationChange: state.markers.elevationChange,
    directionsInstance: state.maps.directionsInstance,
    mapInstance: state.maps.mapInstance,
    lastElevationPing: state.markers.lastElevationPing,
    elevationIntervalId: state.markers.elevationIntervalId,
    delayedElevationRoute: state.markers.delayedElevationRoute,
  }
};

class Controls extends React.Component {

  render() {
    let {
      totalDistance,
      elevationChange,
    } = this.props;

    return (
      <div className="Controls">
        <button
          className="undo"
          onClick={(e) => this.undo()}
        >
          <img src={undo} alt="undo" />
          <label>undo</label>
        </button>

        <button
          className="clear"
          onClick={(e) => this.clear()}
        >
          <img src={clear} alt="clear" />
          <label>clear</label>
        </button>

        <div className="metric total-distance">
          <p>{totalDistance || 0} mi</p>
        </div>

        <div className="metric elevation-change">
          <p>{elevationChange || 0} ft</p>
        </div>
      </div>
    )
  }

  undo = async () => {
    let {
      markers,
    } = this.props;

    let updatedMarkers = _.orderBy(markers, ['position'],['desc']);
    (updatedMarkers.length > 0) && _.remove(updatedMarkers, (m) => (m.position === updatedMarkers[0].position));

    updatedMarkers = _.orderBy(updatedMarkers, ['position'],['asc']);
    await this.props.undo(updatedMarkers);
    this.props.directionsInstance && this.props.directionsInstance.query(this.props.markers, this.handleDirectionsResults);

  }

  clear = () => {
    if ((this.props.markers.length > 0) && window.confirm('Clear current route?')) {
      this.props.clear();
      this.props.directionsInstance && this.props.directionsInstance.clearDisplay();
      this.props.mapInstance && this.props.mapInstance.clearMarkers();
    }
  }

  handleDirectionsResults = (response, status) => {
    const usageCost = (this.props.markers.length >= 13) ? 0.01 : 0.005;
    this.props.directionsInstance.onResults(response);
    this.props.updateUsageCost(usageCost);
    if (response.routes[0]) {
      const distance = this.props.directionsInstance.calculateDistance(response.routes[0]);
      this.props.updateTotalDistance(distance);
      this.maybeRequestElevation(response.routes[0]);
      this.updateDirectionsList(response.routes[0]);
    }
  }

  maybeRequestElevation = (route) => {
    let { elevationIntervalId, lastElevationPing } = this.props;
    const now = DateTime.local();
    let intervalId;

    if (!lastElevationPing || (now.diff(lastElevationPing, 'seconds').seconds > 2)) {
      this.props.updateLastElevationPing(now);
      ElevationHelper.fetchElevation(route, this.handleElevationResults);
      return
    }

    if (lastElevationPing && (now.diff(lastElevationPing, 'seconds').seconds <= 2)) {
      if (elevationIntervalId) {
        clearTimeout(elevationIntervalId);
      }

      intervalId = setTimeout(this.delayedElevationFetch, 2000);
      this.props.setElevationTimeoutData(intervalId, route);
      return
    }

    ElevationHelper.fetchElevation(route, this.handleElevationResults);
  }

  delayedElevationFetch = () => {
    let { delayedElevationRoute } = this.props;
    if (!delayedElevationRoute) {
      return
    }

    ElevationHelper.fetchElevation(delayedElevationRoute, this.handleElevationResults)
  }

  handleElevationResults = (results, status) => {
    if (!results) {
      return;
    }
    
    this.props.updateElevationData(results);
    this.props.updateUsageCost(0.005);
    const elevation = ElevationHelper.calculateElevation(results);
    this.props.updateElevationChange(elevation);
  }

  updateDirectionsList = (route) => {
    this.props.updateDirectionsList([]);
    if (!route) {
      return
    }
    let steps = [];
    if (route.legs && (route.legs.length > 0)) {
      route.legs.forEach((leg, i) => {
        if (leg.steps && (leg.steps.length > 0)) {
          leg.steps.forEach((step, ix) => {
            steps.push(step);
          });
        }
      });
    }

    this.props.updateDirectionsList(steps);
  }


}


export default connect(mapStateToProps, mapDispatchToProps)(Controls);

